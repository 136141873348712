import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import gameTitle from "../assets/an-elmwood-trail/title.png";
import playstore from "../assets/an-elmwood-trail/playstore.png";
import appstore from "../assets/an-elmwood-trail/appstore.png";
import techyonic from "../assets/an-elmwood-trail/techyonic.png";
import instagram from "../assets/an-elmwood-trail/instagram.png";
import twitter from "../assets/an-elmwood-trail/twitter.png";
import discord from "../assets/an-elmwood-trail/discord.png";
import merch from "../assets/an-elmwood-trail/merch.png";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import options from "../config/particles.json";

const trailerLink = "https://bit.ly/techyonic-an-elmwood-trail-trailer";

const socials = [
  {
    id: "Twitter",
    image: twitter,
    link: "https://bit.ly/techyonic-twitter",
    aspectRatio: 1.215,
  },
  {
    id: "Instagram",
    image: instagram,
    link: "https://bit.ly/techyonic-instagram",
    aspectRatio: 1,
  },
  {
    id: "Discord",
    image: discord,
    link: "https://bit.ly/techyonic-discord",
    aspectRatio: 1.319,
  },
];

const androidLink = "https://bit.ly/techyonic-an-elmwood-trail-android";
const iOSLink = "https://bit.ly/techyonic-an-elmwood-trail-ios";

const hereAndroidLink = "https://bit.ly/here-android";
const hereIosLink = "https://bit.ly/here-ios";

export default function Landing() {
  const navigate = useNavigate();

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  function getOS() {
    var userAgent = window.navigator.userAgent,
      platform =
        window.navigator?.userAgentData?.platform || window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (/Linux/.test(platform)) {
      os = "Linux";
    }

    return os;
  }

  const currentOS = getOS();

  return (
    <div className="container">
      <Helmet>
        <title>Techyonic</title>
        <link rel="canonical" href="https://techyonic.co" />
      </Helmet>
      <Particles id="tsparticles" init={particlesInit} options={options} />
      <div className="top">
        <img alt="Game Title" src={gameTitle} />
        <h1>An Elmwood Trail is an interactive mystery game.</h1>
        <button id="watch" onClick={() => window.open(trailerLink)}>
          Watch the trailer
        </button>
        <p id="preorder">Play now for free</p>
        <div id="stores">
          {currentOS !== "iOS" && (
            <button
              className={currentOS === "Android" ? "solo" : ""}
              onClick={() => window.open(androidLink)}>
              <img alt="Play Store" src={playstore} />
            </button>
          )}
          {currentOS !== "Android" && (
            <button
              className={currentOS === "iOS" ? "solo" : ""}
              onClick={() => window.open(iOSLink)}>
              <img alt="App Store" src={appstore} />
            </button>
          )}
        </div>
        <button id="shop-merch" onClick={() => navigate("/merch")}>
          <img alt="Merch" src={merch} />
          <p>Shop Merch</p>
        </button>
      </div>
      <div className="bottom">
        <div className="section">
          <button>
            <img alt="Logo - Techyonic" src={techyonic} />
          </button>
          {socials.map((item) => (
            <button onClick={() => window.open(item.link)}>
              <img alt={item.id} src={item.image} />
            </button>
          ))}
        </div>
        <div className="section right">
          <div
            id="here"
            className="card"
            onClick={() =>
              window.open(currentOS === "iOS" ? hereIosLink : hereAndroidLink)
            }>
            <p>here</p>
          </div>
          <a id="swiftle" className="card" href="/swiftle">
            <p>Swiftle</p>
          </a>
        </div>
      </div>
    </div>
  );
}
