import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faCircleQuestion,
  faChartSimple,
  faGear,
} from "@fortawesome/free-solid-svg-icons";

export default function Header({
  setIsHowToPlayOpen,
  setIsTimelessSelectorOpen,
  setIsStatsOpen,
  setIsSettingsOpen,
  hideTimeless,
  hideStats,
}) {
  return (
    <div className="gomezle-header">
      <div className="header-content">
        <div className="header-left">
          <button onClick={() => setIsHowToPlayOpen(true)}>
            <FontAwesomeIcon icon={faCircleQuestion} className="icon" />
          </button>
          {!hideTimeless && (
            <button onClick={() => setIsTimelessSelectorOpen(true)}>
              <FontAwesomeIcon
                style={{ fontSize: 22 }}
                icon={faCalendarDays}
                className="icon"
              />
            </button>
          )}
        </div>
        <div className="header-title">
          <a href="/gomezle">
            <h1>Gomezle</h1>
          </a>
          <a href="/gomezle">
            <h2>Selena Gomez Heardle</h2>
          </a>
        </div>
        <div className="header-right">
          {!hideStats && (
            <button onClick={() => setIsStatsOpen(true)}>
              <FontAwesomeIcon icon={faChartSimple} className="icon" />
            </button>
          )}
          <button
            style={{ marginLeft: 6 }}
            onClick={() => setIsSettingsOpen(true)}
          >
            <FontAwesomeIcon icon={faGear} className="icon" />
          </button>
        </div>
      </div>
    </div>
  );
}
