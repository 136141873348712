export function normalizeText(text) {
  return text
    .trim()
    .toLowerCase()
    .replace(/[&\\#,+()$~%.'":;*?<>{}\-/! ]/g, "");
}

export function isAlpha(ch) {
  return /^[A-Z]$/i.test(ch);
}
