import React, { useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";

export default function Bar({ MAX_TIME, score }) {
  const [timerStyle, timerApi] = useSpring(
    () => ({
      from: { width: "100%" },
    }),
    []
  );

  useEffect(() => {
    timerApi.start({
      from: { width: "100%" },
      to: { width: "0%" },
      config: {
        duration: (MAX_TIME - 0.1) * 1000,
      },
    });
  }, [score]);

  return (
    <div className="bar">
      <animated.div id="timer" style={{ ...timerStyle }}></animated.div>
    </div>
  );
}
