import React, { useState, useEffect } from "react";
import { usePopper } from "react-popper";
import moment from "moment";
import Header from "../Header.js";
import DateSelector from "../archive/DateSelector.js";
import HowToPlay from "../modals/HowToPlay.js";
import ModeSelector from "../modals/ModeSelector.js";
import Settings from "../modals/Settings.js";
import AnswerVideo from "../modals/AnswerVideo.js";
import Player from "../Player.js";
import Search from "../Search.js";
import Answer from "../Answer.js";
import songs from "../../../config/swiftle/songs.json";
import answers from "../../../config/swiftle/answers.json";
import toasts from "../../../config/swiftle/toasts.json";
import { normalizeText } from "../../../utils/swiftle/normalizeText.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";

const dateFormat = "DD-MM-YYYY";
const today = moment().startOf("day");
const yesterday = moment().subtract(1, "days").startOf("day");
let CLIP_INITIAL_DURATION = 1;
let CLIP_STEP_DURATION = 1;
const CLIP_ACTUAL_DURATION = 6;

export default function Archive({
  theme,
  setTheme,
  setFocused,
  showToast,
  gameEnded,
  setGameEnded,
  data,
  changeMode,
}) {
  const [audio, setAudio] = useState(null);
  const [date, setDate] = useState(data?.date || yesterday.format(dateFormat));
  const [isHowToPlayOpen, setIsHowToPlayOpen] = useState(false);
  const [isAnswerOpen, setIsAnswerOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isModeSelectorOpen, setIsModeSelectorOpen] = useState(false);
  const [selectingDate, setSelectingDate] = useState(true);
  const [guesses, setGuesses] = useState([]);
  const [search, setSearch] = useState("");
  const [searchSelected, setSearchSelected] = useState(null);
  const [matchedSongs, setMatchedSongs] = useState([]);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes, forceUpdate } = usePopper(
    referenceElement,
    popperElement,
    { placement: guesses.length < 3 ? "bottom" : "top" }
  );
  const [clipDuration, setClipDuration] = useState(0);
  const [clipPlayed, setClipPlayed] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);
  const [correctGuess, setCorrectGuess] = useState(-1);
  const [answer, setAnswer] = useState(false);

  useEffect(() => {
    console.log(isModeSelectorOpen);
  }, [isModeSelectorOpen]);

  useEffect(() => {
    if (!date) return;
    let ans = answers[date];
    if (ans.includes("-202")) {
      setAudio(new Audio(`../swiftle/clips/${ans}.mp3`));
    } else {
      setAudio(new Audio(`../swiftle/clips/${date}.mp3`));
    }
  }, [date]);

  useEffect(() => {
    if (!date) return;
    setGuesses([]);
    setAnswer(
      songs.find(
        (s) =>
          s.name ===
          (answers[date].includes("-202")
            ? answers[answers[date]]
            : answers[date])
      )
    );
    setCorrectGuess(-1);
  }, [audio, date]);

  useEffect(() => {
    if (search.trim() === "" || searchSelected) {
      setMatchedSongs([]);
      return;
    }

    let normalizedSearch = normalizeText(search);

    let matched = songs.filter((s) =>
      normalizeText(s.name).startsWith(normalizedSearch)
    );
    matched = matched.concat(
      ...songs.filter(
        (s) =>
          !matched.find((m) => m.name === s.name) &&
          normalizeText(s.name).includes(normalizedSearch)
      )
    );

    setMatchedSongs(matched);
  }, [search, searchSelected]);

  useEffect(() => {
    if (forceUpdate) forceUpdate();
    setSearch("");
    setSearchSelected(null);
    if (guesses.length > 0) {
      let guessedCorrectly = guesses[guesses.length - 1].name === answer.name;

      if (guessedCorrectly) {
        setClipDuration(CLIP_ACTUAL_DURATION);
        setCorrectGuess(guesses.length - 1);
        setGameEnded(true);
      } else if (guesses.length >= 6) {
        setClipDuration(CLIP_ACTUAL_DURATION);
        showToast(toasts["-"]);
        setGameEnded(true);
      } else {
        setClipDuration(
          CLIP_INITIAL_DURATION + guesses.length * CLIP_STEP_DURATION
        );
      }
    } else {
      setGameEnded(false);
      setClipDuration(CLIP_INITIAL_DURATION);
    }
  }, [guesses, forceUpdate, answer]);

  useEffect(() => {
    if (correctGuess === -1) return;

    showToast(toasts[`${correctGuess + 1}`]);
  }, [correctGuess]);

  const handleGuess = () => {
    if (search.trim() === "" || !searchSelected) return;

    if (!clipPlayed && guesses.length === 0) {
      showToast("You haven't listened to the clip yet");
      return;
    }

    setUserInteracted(true);

    setGuesses((guesses) => {
      return [...guesses, searchSelected];
    });
  };

  return (
    <>
      <Header
        subtitle="Archive"
        hideStats
        setIsHowToPlayOpen={setIsHowToPlayOpen}
        setIsSettingsOpen={setIsSettingsOpen}
        setIsModeSelectorOpen={setIsModeSelectorOpen}
      />
      <div className="swiftle-playfield">
        {selectingDate ? (
          <DateSelector
            theme={theme}
            onSelect={(date) => {
              setDate(date);
              setSelectingDate(false);
            }}
          />
        ) : (
          <>
            <Player
              theme={theme}
              date={date}
              audio={audio}
              clipDuration={clipDuration}
              guesses={guesses}
              answer={answer}
              setClipPlayed={setClipPlayed}
              userInteracted={userInteracted}
              correctGuess={correctGuess}
              maxLength={6}
            />
            <div className="date-changer">
              <button
                onClick={() => {
                  let yesterday = moment(date, dateFormat)
                    .subtract(1, "days")
                    .format(dateFormat);
                  if (answers[yesterday]) setDate(yesterday);
                }}>
                {answers[
                  moment(date, dateFormat)
                    .subtract(1, "days")
                    .format(dateFormat)
                ] && <FontAwesomeIcon icon={faCaretLeft} className="icon" />}
              </button>

              <p onClick={() => setSelectingDate(true)}>
                {date}
                <br />
              </p>
              <button
                onClick={() => {
                  let tomorrow = moment(date, dateFormat)
                    .add(1, "days")
                    .format(dateFormat);
                  if (
                    !moment(date, dateFormat)
                      .add(1, "days")
                      .isSameOrAfter(today)
                  )
                    setDate(tomorrow);
                }}>
                {!moment(date, dateFormat)
                  .add(1, "days")
                  .isSameOrAfter(today) && (
                  <FontAwesomeIcon icon={faCaretRight} className="icon" />
                )}
              </button>
            </div>
            <div className="guesses">
              {guesses.map((item, index) => (
                <div
                  key={index}
                  className={`guess ${
                    index === correctGuess ? "correct" : ""
                  } ${theme.cbMode ? "cbmode" : ""}`}>
                  <p className="name">{item.name}</p>
                </div>
              ))}
            </div>
            {!gameEnded && (
              <Search
                theme={theme}
                setReferenceElement={setReferenceElement}
                search={search}
                setSearch={setSearch}
                searchSelected={searchSelected}
                setSearchSelected={setSearchSelected}
                handleGuess={handleGuess}
                setFocused={setFocused}
                matchedSongs={matchedSongs}
                guesses={guesses}
              />
            )}
            <div
              className={`songs ${
                matchedSongs.length > 0
                  ? guesses.length < 3
                    ? "songs-has-songs"
                    : "songs-has-songs-alt"
                  : ""
              }`}
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}>
              {matchedSongs.slice(0, 3).map((item) => (
                <div
                  key={item.name}
                  className="song"
                  onClick={() => {
                    setSearch(item.name);
                    setSearchSelected(item);
                    setMatchedSongs([]);
                  }}>
                  <p>{item.name}</p>
                </div>
              ))}
            </div>

            {guesses.length < 6 && (
              <div className="guesses">
                {[
                  ...Array(
                    5 - guesses.length + (correctGuess !== -1 ? 1 : 0)
                  ).keys(),
                ].map((item, index) => (
                  <div key={index} className="guess empty">
                    <p></p>
                  </div>
                ))}
              </div>
            )}
            {gameEnded && (
              <Answer
                theme={theme}
                answer={answer}
                setIsAnswerOpen={setIsAnswerOpen}
              />
            )}
          </>
        )}
      </div>
      <ModeSelector
        theme={theme}
        isOpen={isModeSelectorOpen}
        setIsOpen={setIsModeSelectorOpen}
        changeMode={(mode, data) => {
          setIsModeSelectorOpen(false);
          changeMode(mode, data);
        }}
      />

      <HowToPlay
        theme={theme}
        isOpen={isHowToPlayOpen}
        setIsOpen={setIsHowToPlayOpen}
      />

      <Settings
        mode="archive"
        theme={theme}
        setTheme={setTheme}
        isOpen={isSettingsOpen}
        setIsOpen={setIsSettingsOpen}
      />
      <AnswerVideo
        theme={theme}
        answer={answer}
        isOpen={isAnswerOpen}
        setIsOpen={setIsAnswerOpen}
      />
    </>
  );
}
