import React, { useState, useEffect } from "react";
import Helmet from "../components/gomezle/Helmet.js";
import Classic from "../components/gomezle/modes/Classic.js";
import Timeless from "../components/gomezle/modes/Timeless.js";
import Update from "../components/gomezle/modals/Update.js";

const VERSION = 1;

export default function Gomezle() {
  const modes = {
    classic: { name: "Classic", Component: Classic },
    timeless: { name: "Timeless", Component: Timeless },
  };
  const [mode, setMode] = useState(modes.classic);
  const [theme, setTheme] = useState({ cbMode: false, darkMode: false });
  const [toast, setToast] = useState("");
  const [toastVisible, setToastVisible] = useState(false);
  const [focused, setFocused] = useState(false);
  const [gameEnded, setGameEnded] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  let timeout1, timeout2;

  useEffect(() => {
    let version = parseInt(localStorage.getItem("gomezle-version"));
    if (!version) {
      localStorage.setItem("gomezle-version", VERSION);
      if (VERSION !== 0) setIsUpdateOpen(true);
    } else if (version < VERSION) {
      localStorage.setItem("gomezle-version", VERSION);
      setIsUpdateOpen(true);
    }

    let savedTheme = JSON.parse(localStorage.getItem("gomezle-theme"));
    if (!savedTheme) {
      localStorage.setItem(
        "gomezle-theme",
        JSON.stringify({ cbMode: false, darkMode: false })
      );
    } else {
      setTheme(savedTheme);
    }
  }, []);

  useEffect(() => {
    console.log(theme);
    localStorage.setItem("gomezle-theme", JSON.stringify(theme));
  }, [theme]);

  const showToast = (text) => {
    if (timeout1) clearTimeout(timeout1);
    if (timeout2) clearTimeout(timeout2);
    setToast(text);
    setToastVisible(true);
    timeout1 = setTimeout(() => setToastVisible(false), 2000);
    timeout2 = setTimeout(() => setToast(""), 2300);
    return () => {
      if (timeout1) clearTimeout(timeout1);
      if (timeout2) clearTimeout(timeout2);
    };
  };

  const changeMode = (m, data) => {
    if (m === "timeless") {
      setMode(() => {
        let newMode = modes[m];
        newMode.data = data;
        return newMode;
      });
    } else {
      setMode(modes[m]);
    }
  };

  const renderMode = (m) => {
    const Component = m.Component;
    return (
      <Component
        theme={theme}
        setTheme={setTheme}
        setFocused={setFocused}
        showToast={showToast}
        gameEnded={gameEnded}
        setGameEnded={setGameEnded}
        changeMode={changeMode}
        data={m.data}
      />
    );
  };

  return (
    <div className={`gomezle-control ${theme.darkMode ? "dark" : "light"}`}>
      <Helmet />
      {renderMode(mode)}

      {!focused && (
        <div className="footer">
          <p>❤️ Gomezle?</p>
          <a href="https://ko-fi.com/techyonic">Help keep Gomezle alive!</a>
        </div>
      )}
      <div className={`toast ${toastVisible ? "visible" : ""}`}>
        <p>{toast}</p>
      </div>
      <Update theme={theme} isOpen={isUpdateOpen} setIsOpen={setIsUpdateOpen} />
    </div>
  );
}
