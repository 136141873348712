import React, { useState, useEffect, useCallback } from "react";
import Helmet from "../components/swiftle/Helmet.js";
import Classic from "../components/swiftle/modes/Classic.js";
import Archive from "../components/swiftle/modes/Archive.js";
import Rapid from "../components/swiftle/modes/Rapid.js";
import Lyrics from "../components/swiftle/modes/Lyrics.js";
import BlankSpace from "../components/swiftle/modes/BlankSpace.js";
import Update from "../components/swiftle/modals/Update.js";
import taylorBirthday from "../assets/taylor-birthday.jpg";
import taylor1989 from "../assets/taylor-1989.png";
import taylorTTPD from "../assets/taylor-ttpd.jpeg";
import moment from "moment";
import { useParams } from "react-router-dom";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import options from "../config/blueparticles.json";

const VERSION = 27;

const dateFormat = "DD-MM-YYYY";

export default function Swiftle() {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const params = useParams();
  const modes = {
    classic: { name: "Classic", Component: Classic },
    archive: { name: "Archive", Component: Archive },
    rapid: { name: "Rapid", Component: Rapid },
    lyrics: { name: "Lyrics", Component: Lyrics },
    "blank-space": { name: "Blank Space", Component: BlankSpace },
  };
  const mode =
    params.mode && modes[params.mode] ? modes[params.mode] : modes["classic"];
  const [theme, setTheme] = useState({ cbMode: false, darkMode: true });
  const [toast, setToast] = useState("");
  const [toastVisible, setToastVisible] = useState(false);
  const [focused, setFocused] = useState(false);
  const [gameEnded, setGameEnded] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  let timeout1, timeout2;

  useEffect(() => {
    let version = parseInt(localStorage.getItem("swiftle-version"));
    if (!version) {
      localStorage.setItem("swiftle-version", VERSION);
      setIsUpdateOpen(true);
    } else if (version < VERSION) {
      localStorage.setItem("swiftle-version", VERSION);
      setIsUpdateOpen(true);
    }

    let savedTheme = JSON.parse(localStorage.getItem("swiftle-theme"));
    if (!savedTheme) {
      localStorage.setItem(
        "swiftle-theme",
        JSON.stringify({ cbMode: false, darkMode: true })
      );
    } else {
      setTheme({ ...savedTheme, darkMode: true });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("swiftle-theme", JSON.stringify(theme));
  }, [theme]);

  const showToast = (text) => {
    if (timeout1) clearTimeout(timeout1);
    if (timeout2) clearTimeout(timeout2);
    setToast(text);
    setToastVisible(true);
    timeout1 = setTimeout(() => setToastVisible(false), 2000);
    timeout2 = setTimeout(() => setToast(""), 2300);
    return () => {
      if (timeout1) clearTimeout(timeout1);
      if (timeout2) clearTimeout(timeout2);
    };
  };

  const renderMode = (m) => {
    const Component = m.Component;
    return (
      <Component
        theme={theme}
        setTheme={setTheme}
        setFocused={setFocused}
        showToast={showToast}
        gameEnded={gameEnded}
        setGameEnded={setGameEnded}
        setIsUpdateOpen={setIsUpdateOpen}
        data={m.data}
      />
    );
  };

  return (
    <div className={`swiftle-control ${theme.darkMode ? "dark" : "light"}`}>
      <Helmet title={mode.name} />
      <div
        style={{
          position: "absolute",
          alignSelf: "center",
          maxWidth: "640px",
          width: "100%",
          height: "100%",
        }}>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}>
          <Particles
            id="tsparticles"
            init={particlesInit}
            options={options}
            style={{
              pointerEvents: "none",
              zIndex: -4,
            }}
          />
        </div>
      </div>

      {renderMode(mode)}
      {!focused && (
        <div className="footer">
          <a href="https://instagram.com/sparshxtechyon">
            Made by <span>@sparshxtechyon</span>
          </a>
        </div>
      )}
      <div className={`toast ${toastVisible ? "visible" : ""}`}>
        <p>{toast}</p>
      </div>
      <img
        alt="taylor"
        src={
          moment().format(dateFormat) === "13-12-2023"
            ? taylorBirthday
            : taylorTTPD
        }
        style={{
          position: "absolute",
          maxWidth: "640px",
          width: "100%",
          height: "100%",
          opacity: 0.1,
          objectFit: "cover",
          objectPosition: "top",
          pointerEvents: "none",
          zIndex: 2,
        }}
      />
      <Update theme={theme} isOpen={isUpdateOpen} setIsOpen={setIsUpdateOpen} />
    </div>
  );
}
