import React from "react";
import CustomModal from "./CustomModal.js";
import Switch from "react-switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";

export default function Settings(props) {
  return (
    <CustomModal
      {...props}
      theme={props.theme}
      heading={"Settings"}
      contentLabel="Settings"
      ariaHideApp={true}
    >
      <div className="settings">
        {props.mode === "classic" && (
          <>
            <div className="heading">
              <h4>Difficulty</h4>
              <p>Changes will be applied from tomorrow</p>
            </div>
            <div className="row">
              <div>
                <p>Hard Mode</p>
                <p className="description">
                  Half the clip (3 secs) played in 0.5 second parts
                </p>
              </div>
              <Switch
                onChange={props.toggleHardMode}
                checked={
                  props.hardMode.nextChange === ""
                    ? props.hardMode.enabled
                    : !props.hardMode.enabled
                }
                uncheckedIcon={false}
                checkedIcon={false}
                handleDiameter={16}
                width={48}
                onColor={"#6fc392"}
                offColor={"#666666"}
              />
            </div>
          </>
        )}
        <div className="heading">
          <h4>General</h4>
        </div>
        <div className="row">
          <p>Dark Mode</p>
          <Switch
            onChange={() =>
              props.setTheme((theme) => ({
                cbMode: theme.cbMode,
                darkMode: !theme.darkMode,
              }))
            }
            checked={props.theme.darkMode}
            uncheckedIcon={false}
            checkedIcon={false}
            handleDiameter={16}
            width={48}
            onColor={"#6fc392"}
            offColor={"#666666"}
          />
        </div>
        <div className="row">
          <p>High Contrast</p>
          <Switch
            onChange={() =>
              props.setTheme((theme) => ({
                cbMode: !theme.cbMode,
                darkMode: theme.darkMode,
              }))
            }
            checked={props.theme.cbMode}
            uncheckedIcon={false}
            checkedIcon={false}
            handleDiameter={16}
            width={48}
            onColor={"#6fc392"}
            offColor={"#666666"}
          />
        </div>
        <div className="row">
          <p>Report an issue</p>
          <a href="mailto:sparshtyagi21@gmail.com">Email</a>
        </div>
        <div className="social">
          <div className="buttons">
            <button
              onClick={() => {
                window.open("https://twitter.com/techyonic", "_blank");
              }}
            >
              <FontAwesomeIcon className="icon" icon={faTwitter} />
            </button>
            <button
              onClick={() => {
                window.open("https://www.instagram.com/techyonic/", "_blank");
              }}
            >
              <FontAwesomeIcon className="icon" icon={faInstagram} />
            </button>
          </div>
          <p className="disclaimer">
            Gomezle is a gracious homage to Selena Gomez and inspired by Wordle
            & Heardle. <br />
            The clips used are simply for non profit entertainment purposes only
            and any further usage is not intended. All the content is copyright
            to the respective owner and is protected under copyright laws.
          </p>
          <p
            className="techyonic"
            onClick={() => {
              window.open("https://techyonic.co", "_blank");
            }}
          >
            techyonic
          </p>
          <p className="disclaimer">Created by a team of one.</p>
        </div>
      </div>
    </CustomModal>
  );
}
