import React from "react";
import CustomModal from "./CustomModal.js";
import answers from "../../../config/gomezle/answers.json";
import moment from "moment";

const dateFormat = "DD-MM-YYYY";
const today = moment().startOf("day");

export default function TimelessSelector(props) {
  return (
    <CustomModal
      {...props}
      heading={"Past Gomezles"}
      contentLabel="Timeless"
      ariaHideApp={true}
    >
      <p style={{ margin: 10, fontSize: 12 }}>
        Progress and stats aren't saved for past games
      </p>
      <div className="modal-timeless">
        {Object.keys(answers).map((item) => {
          let date = moment(item, dateFormat);
          if (date.isSameOrAfter(today)) return null;
          return (
            <div
              key={item}
              className="date"
              onClick={() => props.changeMode("timeless", { date: item })}
            >
              <p className="day">{moment(item, dateFormat).format("DD")}</p>
              <p className="month-year">
                {moment(item, dateFormat).format("MMMM")}
                <br />
                {moment(item, dateFormat).format("YYYY")}
              </p>
            </div>
          );
        })}
      </div>
      <div className="daily" onClick={() => props.changeMode("classic")}>
        <p>Daily Gomezle</p>
      </div>
    </CustomModal>
  );
}
