import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faQuestion } from "@fortawesome/free-solid-svg-icons";
import {
  CircularProgressbarWithChildren,
  CircularProgressbar,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

let interval, timeout;
export default function Player({
  hide,
  theme,
  date,
  autoplay,
  audio,
  audioStart,
  audioLoaded,
  clipDuration,
  setClipPlayed,
  userInteracted,
  maxLength,
  actualLength,
}) {
  const fullLength = actualLength || 6;
  const [clipPlaying, setClipPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressStep, setProgressStep] = useState(0);

  useEffect(() => {
    let newProgress = progressStep * 10;
    setProgress(newProgress);
  }, [progressStep]);

  useEffect(() => {
    setProgress(0);
  }, [date]);

  useEffect(() => {
    if (!audio) return;
    if (!audioLoaded) {
      setProgress(0);
      setProgressStep(0);
      audio.pause();
      clearTimeout(timeout);
      clearInterval(interval);
      audio.currentTime = audioStart || 0;
      audio.load();
      setClipPlaying(false);
    }
  }, [audio, audioLoaded]);

  useEffect(() => {
    if (audioStart === -1) return;
    if (autoplay) playClip();
    else if (clipDuration / (maxLength / fullLength) !== 1) playClip();
  }, [audioStart, clipDuration]);

  const playClip = (clickedPlayButton) => {
    if (!audio) return;
    if (!userInteracted && !clickedPlayButton) return;
    if (clipPlaying) return;

    setClipPlaying(true);
    setClipPlayed(true);
    setProgressStep(0);
    audio.currentTime = audioStart || 0;
    audio.play();
    interval = setInterval(
      () =>
        setProgressStep((progressStep) => {
          if ((progressStep + 1) / 10 > clipDuration) return progressStep;
          return progressStep + 1;
        }),
      90
    );

    timeout = setTimeout(() => {
      audio.pause();
      clearInterval(interval);
      audio.currentTime = audioStart || 0;
      audio.load();
      setClipPlaying(false);
    }, clipDuration * 1000 + 100);
  };

  return (
    <div className="play" style={{ opacity: hide ? 0 : 1 }}>
      <CircularProgressbarWithChildren
        value={(clipDuration * (100 / fullLength)) / (maxLength / fullLength)}
        maxValue={100}
        circleRatio={maxLength / fullLength}
        strokeWidth={5}
        styles={buildStyles({
          trailColor: theme.darkMode ? "#2c2c2c" : "#f2f2f2",
          pathColor: theme.darkMode ? "#4c4c4c" : "#FFC2C2",
          pathTransitionDuration: 0.15,
        })}>
        {/* Foreground path */}
        <CircularProgressbar
          value={progress}
          maxValue={clipDuration * 100}
          circleRatio={((100 / fullLength) * clipDuration) / 100}
          strokeWidth={5}
          styles={buildStyles({
            trailColor: "transparent",
            pathColor: theme.darkMode ? "#ff7070cc" : "#ff7070",
            pathTransition:
              progress === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s",
          })}
        />
      </CircularProgressbarWithChildren>
      <button
        onClick={() => {
          playClip(true);
        }}
        onPointerDown={(e) => e.preventDefault()}>
        <FontAwesomeIcon className="icon" icon={faPlay} />
      </button>
    </div>
  );
}
