import React from "react";
import { useNavigate } from "react-router-dom";
import answers from "../../../config/swiftle/answers.json";
import moment from "moment";

const dateFormat = "DD-MM-YYYY";
const today = moment().startOf("day");

export default function DateSelector({ onSelect }) {
  const navigate = useNavigate();

  return (
    <div className="date-selector">
      <p style={{ margin: 10, fontSize: 12 }}>
        Progress and stats aren't saved for past games
      </p>
      <div className="dates">
        {Object.keys(answers).map((item) => {
          let date = moment(item, dateFormat);
          if (date.isSameOrAfter(today)) return null;
          return (
            <div key={item} className="date" onClick={() => onSelect(item)}>
              <p className="day">{moment(item, dateFormat).format("DD")}</p>
              <p className="month-year">
                {moment(item, dateFormat).format("MMMM")}
                <br />
                {moment(item, dateFormat).format("YYYY")}
              </p>
            </div>
          );
        })}
      </div>
      <div className="daily" onClick={() => navigate("/swiftle")}>
        <p>Daily Swiftle</p>
      </div>
    </div>
  );
}
