import React from "react";
import { isAlpha } from "../../../utils/swiftle/normalizeText";

export default function Lyric({ n, lyrics, lineId, startId, guess, answer }) {
  const getSplit = (word, index) =>
    `${word
      .split("")
      .map((c, i) => {
        const inclSpecialChars = lyrics[n]
          .slice(startId, index)
          .concat([word.substring(0, i)])
          .join("")
          .split("")
          .filter((c) => isAlpha(c));
        const blankIndex = inclSpecialChars.length;
        return isAlpha(c)
          ? blankIndex < guess.length
            ? guess.charAt(blankIndex)
            : "_"
          : c;
      })
      .join("")} `;
  return (
    <p>
      {lyrics[n].map((word, index) => (
        <span key={`${n}-${index}`}>
          {lineId === n && startId <= index
            ? getSplit(word, index)
            : `${word} `}
        </span>
      ))}
    </p>
  );
}
