import React from "react";
import CustomModal from "./CustomModal.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import {
  CircularProgressbarWithChildren,
  CircularProgressbar,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function HowToPlay(props) {
  const renderPlay = (progress, clipDuration) => (
    <div className="play">
      <CircularProgressbarWithChildren
        value={clipDuration * 16.66667}
        maxValue={100}
        circleRatio={1}
        strokeWidth={6}
        styles={buildStyles({
          trailColor: props.theme.darkMode ? "#2c2c2e" : "#f2f2f2",
          pathColor: props.theme.darkMode ? "#2A6543" : "#C5E7D3",
          pathTransitionDuration: 0.15,
        })}
      >
        {/* Foreground path */}
        <CircularProgressbar
          value={progress}
          maxValue={clipDuration * 100}
          circleRatio={(16.67 * clipDuration) / 100}
          strokeWidth={6}
          styles={buildStyles({
            trailColor: "transparent",
            pathColor: props.theme.darkMode ? "#6fc392cc" : "#6fc392",
            pathTransition:
              progress === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s",
          })}
        />
      </CircularProgressbarWithChildren>
      <button>
        <FontAwesomeIcon className="icon" icon={faPlay} />
      </button>
    </div>
  );

  const renderGuess = (text, correct) => (
    <div className="guesses">
      <div
        className={`guess ${correct ? "correct" : ""} ${
          props.theme.cbMode ? "cbmode" : ""
        }`}
      >
        <p>{text}</p>
      </div>
    </div>
  );

  return (
    <CustomModal
      {...props}
      heading={"How To Play"}
      contentLabel="How To Play"
      ariaHideApp={true}
    >
      <p className="modal-text">
        Listen to the clip and guess the Selena Gomez song in 6 tries. The clip
        can be from <strong>any random part of the song</strong>. It is
        initially of one second but after each guess, 1 more second of the clip
        is unlocked. Hit the play button to play the clip every time.
      </p>
      {renderPlay(0, 1)}
      <p className="modal-text">
        The initial 1 second clip is available to play.
      </p>
      {renderPlay(100, 2)}
      {renderGuess("Good For You", false)}
      <p className="modal-text">
        After a wrong guess, 1 more second of the clip is unlocked and is
        available to play.
      </p>
      {renderPlay(200, 2)}
      {renderGuess("Good For You", false)}
      {renderGuess("Same Old Love", true)}
      <p className="modal-text">
        After listening to 2 seconds of unlocked clip, the correct answer is
        guessed.
      </p>
    </CustomModal>
  );
}
