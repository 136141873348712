import React from "react";
import { useNavigate } from "react-router-dom";
import CustomModal from "./CustomModal.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFire, faBox } from "@fortawesome/free-solid-svg-icons";
import {
  faListMusic,
  faScalpelLineDashed,
} from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import dailyBG from "../../../assets/swiftle-daily-bg.png";

const dateFormat = "DD-MM-YYYY";
const yesterday = moment().subtract(1, "days").startOf("day");

export default function ModeSelector(props) {
  const navigate = useNavigate();

  const selectMode = (path) => {
    navigate(`/swiftle${path}`);
    props.setIsOpen(false);
  };

  return (
    <CustomModal
      {...props}
      heading={"Game Modes"}
      contentLabel="Modes"
      ariaHideApp={true}>
      <div className="mode-selector">
        <div className="modes">
          <button className="mode" onClick={() => selectMode("/rapid")}>
            <FontAwesomeIcon icon={faFire} className="icon" />
            <p>Rapid</p>
            {/* <div id="new">POPULAR</div> */}
          </button>
          <button className="mode" onClick={() => selectMode("/blank-space")}>
            <FontAwesomeIcon icon={faScalpelLineDashed} className="icon" />
            <p>Blank Space</p>
            <div id="new">NEW</div>
          </button>
          <button className="mode" onClick={() => selectMode("/lyrics")}>
            <FontAwesomeIcon icon={faListMusic} className="icon" />
            <p>Lyrics</p>
          </button>
          <button className="mode" onClick={() => selectMode("/archive")}>
            <FontAwesomeIcon icon={faBox} className="icon" />
            <p>Archive</p>
          </button>
        </div>
        <button
          className="daily-mode"
          onClick={() => selectMode("/")}
          style={{ backgroundImage: `url(${dailyBG})` }}>
          <p>Daily Swiftle</p>
        </button>
      </div>
    </CustomModal>
  );
}
