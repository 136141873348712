import React from "react";
import { Helmet } from "react-helmet";

export default function Merch() {
  return (
    <div id="myShop">
      <Helmet title="Techyonic - Merch">
        <script
          type="text/javascript"
          src="https://techyonic.myspreadshop.de/shopfiles/shopclient/shopclient.nocache.js"></script>
      </Helmet>
      <a href="https://techyonic.myspreadshop.de">techyonic</a>
    </div>
  );
}
