import React from "react";
import CustomModal from "./CustomModal.js";
import { useNavigate } from "react-router-dom";

const trailerLink = "https://bit.ly/techyonic-an-elmwood-trail-trailer";
const androidLink = "https://bit.ly/techyonic-an-elmwood-trail-android";
const iOSLink = "https://bit.ly/techyonic-an-elmwood-trail-ios";

export default function Update(props) {
  const navigate = useNavigate();

  function getOS() {
    var userAgent = window.navigator.userAgent,
      platform =
        window.navigator?.userAgentData?.platform || window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (/Linux/.test(platform)) {
      os = "Linux";
    }

    return os;
  }

  const currentOS = getOS();

  return (
    <CustomModal
      {...props}
      heading={"Filling in the blank spaces"}
      contentLabel="What's New"
      ariaHideApp={true}>
      <div
        style={{
          width: "95%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <p style={{ fontSize: 18, lineHeight: "1.5em" }}>
          Some fixes in the new Blank Space mode:
          <ul style={{ textAlign: "left" }}>
            <li>Removed some vocalizations (oh, eh, ah, etc)</li>
            <li>Added a "give up" button</li>
            <li>Fixed repeated lyrics</li>
          </ul>
          I am working on a practice mode for the next update.
          <br />
          <a
            style={{ color: "#ff7070", textDecoration: "underline" }}
            href={"https://instagram.com/sparshxtechyon"}
            target="_blank">
            <strong>Follow to support my work!</strong>
          </a>
        </p>
      </div>
    </CustomModal>
  );
}
