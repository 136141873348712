import React, { useState, useEffect, useRef } from "react";
import { usePopper } from "react-popper";
import moment from "moment";
import Header from "../Header.js";
import HowToPlay from "../modals/HowToPlay.js";
import ModeSelector from "../modals/ModeSelector.js";
import Settings from "../modals/Settings.js";
import songs from "../../../config/swiftle/songs.json";
import lyricsSongs from "../../../config/swiftle/lyrics.json";
import {
  isAlpha,
  normalizeText,
} from "../../../utils/swiftle/normalizeText.js";
import Score from "../blankspace/Score.js";
import Lyric from "../blankspace/Lyric.js";
import Bar from "../blankspace/Bar.js";

const MAX_TIME = 21;

function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

let gameCounterInterval;

const specialChars = /[-()]/;

export default function BlankSpace({
  theme,
  setTheme,
  setFocused,
  showToast,
  changeMode,
}) {
  const inputRef = useRef(null);
  const [lyrics, setLyrics] = useState([[], []]);
  const [lineId, setLineId] = useState(-1);
  const [startId, setStartId] = useState(-1);
  const [guess, setGuess] = useState("");
  const [answer, setAnswer] = useState("");
  const [fullAnswer, setFullAnswer] = useState("");
  const [song, setSong] = useState(null);
  const [usedSongs, setUsedSongs] = useState([]);
  const [gameStarted, setGameStarted] = useState(false);
  const [score, setScore] = useState(0);
  const [lost, setLost] = useState(false);
  const [isHowToPlayOpen, setIsHowToPlayOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isModeSelectorOpen, setIsModeSelectorOpen] = useState(false);
  const [gameCounter, setGameCounter] = useState(MAX_TIME);
  const [guessedCorrectly, setGuessedCorrectly] = useState(false);
  const [state, setState] = useState(null);

  useEffect(() => {
    let lyricsState = JSON.parse(localStorage.getItem("swiftle-blank-space"));

    if (!lyricsState) {
      let newState = {
        lastPlayed: moment(),
        best: 0,
      };
      setState(newState);
      localStorage.setItem("swiftle-blank-space", JSON.stringify(newState));
    } else {
      setState(lyricsState);
    }
  }, []);

  const getNextSong = () => {
    let songIndex = randomInteger(0, lyricsSongs.length - 1);

    while (
      usedSongs.length < 60 &&
      usedSongs.find((s) => s === lyricsSongs[songIndex].id)
    ) {
      songIndex = randomInteger(0, lyricsSongs.length - 1);
    }
    const lyricsSong = lyricsSongs[songIndex];
    const lyrics = lyricsSong.lyrics;

    const minAnswerLength = randomInteger(6, 8);

    const lineIndex = randomInteger(0, 1);

    let lyricIndex = randomInteger(5, lyrics.length - 5);
    let combinedLength =
      lyrics[lyricIndex].split(" ").length +
      lyrics[lyricIndex + 1].split(" ").length;
    console.log(lyrics[lyricIndex]);
    console.log(lyrics[lyricIndex + 1]);
    while (
      lyrics[lyricIndex] === lyrics[lyricIndex + 1] ||
      combinedLength < 10 ||
      combinedLength > 20 ||
      specialChars.test(lyrics[lyricIndex]) ||
      specialChars.test(lyrics[lyricIndex + 1])
    ) {
      lyricIndex = randomInteger(5, lyrics.length - 5);
      combinedLength =
        lyrics[lyricIndex].split(" ").length +
        lyrics[lyricIndex + 1].split(" ").length;
    }
    const firstLine = lyrics[lyricIndex].split(" ");
    const secondLine = lyrics[lyricIndex + 1].split(" ");
    const lyricsArr = [firstLine, secondLine];

    let startIndex = lyricsArr[lineIndex].length - 1;
    let answer = lyricsArr[lineIndex]
      .slice(startIndex)
      .join("")
      .split("")
      .filter((c) => isAlpha(c));
    while (answer.length < minAnswerLength) {
      startIndex -= 1;
      answer = lyricsArr[lineIndex]
        .slice(startIndex)
        .join("")
        .split("")
        .filter((c) => isAlpha(c));
    }
    console.log(answer);
    setFullAnswer(lyricsArr[lineIndex].slice(startIndex).join(" "));
    setAnswer(answer.join(""));
    setLineId(lineIndex);
    setStartId(startIndex);
    setLyrics(lyricsArr);
    setSong({ ...lyricsSong });
    setUsedSongs((usedSongs) => [...usedSongs, lyricsSong.id]);
  };

  useEffect(() => {
    if (!gameStarted) return;
    resetAnimations();
    setGameCounter(MAX_TIME);
    setLost(false);
    setScore(0);
    setUsedSongs([]);
    getNextSong();
  }, [gameStarted]);

  const handleKey = (e) => {
    if (e.key === " " || e.key === "Enter") {
      if (!gameStarted) {
        setGameStarted(true);
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKey);

    return () => window.removeEventListener("keydown", handleKey);
  }, [gameStarted]);

  useEffect(() => {
    handleGuess(guess);
  }, [guess]);

  const handleGuess = (guess) => {
    if (guessedCorrectly || answer === "") return;
    if (normalizeText(guess) === normalizeText(answer)) {
      setScore((score) => score + 1);
      setGuessedCorrectly(true);
      resetAnimations();
      setTimeout(() => {
        setLyrics([[], []]);
        setGuess("");
        getNextSong();
        setGuessedCorrectly(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (lost) {
      setLyrics([[], []]);
      setGuess("");
      setFocused(false);
      resetAnimations();
      setGameStarted(false);
      if (score > state.best) {
        let newState = {
          lastPlayed: moment(),
          best: score,
        };
        setState(newState);
        localStorage.setItem("swiftle-blank-space", JSON.stringify(newState));
      }
    }
  }, [lost, score, state]);

  const resetAnimations = () => {
    setGameCounter(MAX_TIME);
    clearInterval(gameCounterInterval);
  };

  useEffect(() => {
    if (lyrics[0].length === 0 || lyrics[1].length === 0) return;
    gameCounterInterval = setInterval(decrementGameCounter, 1000);
  }, [lyrics]);

  useEffect(() => {
    if (gameCounter === 0) setLost(true);
  }, [gameCounter]);

  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => inputRef.current.focus(), 100);
    }
  }, [inputRef.current]);

  const decrementGameCounter = () => {
    setGameCounter((gameCounter) => {
      if (gameCounter - 1 === 0) {
        clearInterval(gameCounterInterval);
      }
      return gameCounter - 1;
    });
  };

  return (
    <>
      <Header
        subtitle="Blank Space"
        hideStats
        setIsHowToPlayOpen={setIsHowToPlayOpen}
        setIsSettingsOpen={setIsSettingsOpen}
        setIsModeSelectorOpen={setIsModeSelectorOpen}
      />
      <div className="swiftle-blank-space-playfield">
        <Score
          score={score}
          best={state?.best}
          lost={lost}
          showToast={showToast}
        />
        {lost && (
          <div id="missed-answer">
            Answer: <strong>{fullAnswer}</strong>
            <br />
            <span>Song: {song?.name}</span>
          </div>
        )}
        {!gameStarted ? (
          <button
            className={"playbutton"}
            onClick={() => {
              setGameStarted(true);
              inputRef.current.focus();
            }}>
            <p>PLAY</p>
          </button>
        ) : (
          <>
            <div id="lyrics" onClick={() => inputRef.current.focus()}>
              <Lyric
                n={0}
                lyrics={lyrics}
                lineId={lineId}
                startId={startId}
                guess={guess}
                answer={answer}
              />
              <Lyric
                n={1}
                lyrics={lyrics}
                lineId={lineId}
                startId={startId}
                guess={guess}
                answer={answer}
              />
            </div>
            <Bar MAX_TIME={MAX_TIME} score={score} />
            <button
              disabled={MAX_TIME - gameCounter <= 2}
              className={`skip ${MAX_TIME - gameCounter > 2 ? "visible" : ""}`}
              onClick={() => {
                clearInterval(gameCounterInterval);
                setLost(true);
              }}>
              <p>Give Up</p>
            </button>
          </>
        )}

        <div id="inputwrap">
          <input
            ref={inputRef}
            type="search"
            autoCorrect="off"
            autoCapitalize="none"
            onBlur={() => {
              setTimeout(() => inputRef.current.focus(), 50);
            }}
            value={guess}
            onChange={(e) => {
              if (e.target.value.length > answer.length) return;
              setGuess(normalizeText(e.target.value));
            }}
          />
        </div>
        <HowToPlay
          theme={theme}
          isOpen={isHowToPlayOpen}
          setIsOpen={setIsHowToPlayOpen}
        />
        <ModeSelector
          theme={theme}
          isOpen={isModeSelectorOpen}
          setIsOpen={setIsModeSelectorOpen}
          changeMode={(mode, data) => {
            setIsModeSelectorOpen(false);
            changeMode(mode, data);
          }}
        />
        <Settings
          mode="lyrics"
          theme={theme}
          setTheme={setTheme}
          isOpen={isSettingsOpen}
          setIsOpen={setIsSettingsOpen}
        />
      </div>
    </>
  );
}
