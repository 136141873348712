import React from "react";

export default function Answer({ theme, setIsAnswerOpen, answer }) {
  return (
    <div
      className={`answer ${theme.cbMode ? "cbmode" : ""}`}
      onClick={() => setIsAnswerOpen(true)}
    >
      <h5>Answer</h5>
      <p className="name">{answer.name}</p>
      <p className="album-year">
        {answer.album}, {answer.year}
      </p>
      {answer.name === "Monologue Song" && (
        <p style={{ marginTop: 10 }}>Happy April Fool's Day!</p>
      )}
    </div>
  );
}
