import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import {
  CircularProgressbarWithChildren,
  CircularProgressbar,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function Player({
  theme,
  date,
  audio,
  clipDuration,
  guesses,
  setClipPlayed,
  answer,
  userInteracted,
  correctGuess,
  maxLength,
}) {
  const [clipPlaying, setClipPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressStep, setProgressStep] = useState(0);
  let interval;

  useEffect(() => {
    let newProgress = progressStep * 10;
    setProgress(newProgress);
  }, [progressStep]);

  useEffect(() => {
    setProgress(0);
  }, [date]);

  useEffect(() => {
    if (clipDuration / (maxLength / 6) !== 1) playClip();
  }, [clipDuration, maxLength]);

  const playClip = (clickedPlayButton) => {
    if (!userInteracted && !clickedPlayButton) return;
    if (clipPlaying) return;

    setClipPlaying(true);
    setClipPlayed(true);
    setProgressStep(0);
    audio.play();
    interval = setInterval(
      () =>
        setProgressStep((progressStep) => {
          if ((progressStep + 1) / 10 > clipDuration) return progressStep;
          return progressStep + 1;
        }),
      90
    );

    setTimeout(() => {
      audio.pause();
      clearInterval(interval);
      audio.currentTime = 0;
      audio.load();
      setClipPlaying(false);
    }, clipDuration * 1000 + 100);
  };

  return (
    <div className="play">
      <CircularProgressbarWithChildren
        value={(clipDuration * 16.66667) / (maxLength / 6)}
        maxValue={100}
        circleRatio={maxLength / 6}
        strokeWidth={5}
        styles={buildStyles({
          trailColor: theme.darkMode ? "#2c2c2e" : "#f2f2f2",
          pathColor: theme.darkMode ? "#2A6543" : "#C5E7D3",
          pathTransitionDuration: 0.15,
        })}
      >
        {/* Foreground path */}
        <CircularProgressbar
          value={progress}
          maxValue={clipDuration * 100}
          circleRatio={(16.67 * clipDuration) / 100}
          strokeWidth={5}
          styles={buildStyles({
            trailColor: "transparent",
            pathColor: theme.darkMode ? "#6fc392cc" : "#6fc392",
            pathTransition:
              progress === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s",
          })}
        />
      </CircularProgressbarWithChildren>
      <button
        onClick={() => {
          playClip(true);
        }}
      >
        <FontAwesomeIcon className="icon" icon={faPlay} />
      </button>
    </div>
  );
}
