import React from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

const customStyles = {
  overlay: {
    background: "rgba(0, 0, 0, 0.7)",
  },
  content: {
    maxWidth: "520px",
    width: "85%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    fontFamily: "Quicksand",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    borderRadius: 10,
  },
};

export default function CustomModal({
  theme,
  isOpen,
  setIsOpen,
  children,
  heading,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={{
        overlay: { ...customStyles.overlay },
        content: {
          ...customStyles.content,
          backgroundColor: theme?.darkMode ? "#18181a" : "#FFFFFF",
        },
      }}
      contentLabel="How To Play"
      ariaHideApp={true}
      closeTimeoutMS={400}
    >
      <div
        className={`gomezle-modal-control ${
          theme?.darkMode ? "dark" : "light"
        }`}
      >
        <div className="modal-header">
          <div />
          <h3 className="modal-heading">{heading}</h3>
          <div>
            <button onClick={() => setIsOpen(false)}>
              <FontAwesomeIcon icon={faCircleXmark} className="icon" />
            </button>
          </div>
        </div>
        {children}
      </div>
    </Modal>
  );
}
