import React, { useState, useEffect } from "react";
import { usePopper } from "react-popper";
import moment from "moment";
import Header from "../Header.js";
import HowToPlay from "../modals/HowToPlay.js";
import ModeSelector from "../modals/ModeSelector.js";
import Settings from "../modals/Settings.js";
import Player from "../Player.js";
import songs from "../../../config/swiftle/songs.json";
import spotify from "../../../config/swiftle/spotify.json";
import { normalizeText } from "../../../utils/swiftle/normalizeText.js";
import Score from "../rapid/Score.js";
import Search from "../rapid/Search.js";

const TOTAL_LENGTH = 9;
const MAX_TIME = 9;

function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const initTexts = ["Go", "Set", "...Ready?"];
let initCounterInterval, gameCounterInterval;

export default function Rapid({
  theme,
  setTheme,
  setFocused,
  showToast,
  changeMode,
}) {
  const [song, setSong] = useState(null);
  const [audio, setAudio] = useState(null);
  const [audioStart, setAudioStart] = useState(-1);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const [usedSongs, setUsedSongs] = useState([]);
  const [gameStarted, setGameStarted] = useState(false);
  const [initializing, setInitializing] = useState(false);
  const [initCounter, setInitCounter] = useState(3);
  const [score, setScore] = useState(0);
  const [times, setTimes] = useState([]);
  const [lost, setLost] = useState(false);
  const [isHowToPlayOpen, setIsHowToPlayOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isModeSelectorOpen, setIsModeSelectorOpen] = useState(false);
  const [guessedCorrectly, setGuessedCorrectly] = useState(false);
  const [guessed, setGuessed] = useState(null);
  const [search, setSearch] = useState("");
  const [searchSelected, setSearchSelected] = useState(null);
  const [matchedSongs, setMatchedSongs] = useState([]);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [gameCounter, setGameCounter] = useState(MAX_TIME);
  const { styles, attributes, forceUpdate } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: "bottom",
    }
  );
  const [correctGuess, setCorrectGuess] = useState(-1);
  const [answer, setAnswer] = useState(false);

  const [state, setState] = useState(null);

  useEffect(() => {
    let rapidState = JSON.parse(localStorage.getItem("swiftle-rapid"));

    if (!rapidState) {
      let newState = {
        lastPlayed: moment(),
        best: 0,
      };
      setState(newState);
      localStorage.setItem("swiftle-rapid", JSON.stringify(newState));
    } else {
      setState(rapidState);
    }
  }, []);

  const handleAudioMetadata = (e) => {
    const lastSec = e.target.duration - TOTAL_LENGTH;
    let start = audioStart;
    while (start === audioStart) start = randomInteger(0, lastSec);
    setAudioStart(start);
    setAudioLoaded(true);
    audio.removeEventListener("loadedmetadata", handleAudioMetadata);
  };

  const handleAudioLoaded = (e) => {
    setAudioLoaded(true);
    audio.removeEventListener("canplaythrough", handleAudioLoaded);
  };

  const getNextSong = () => {
    let songIndex = randomInteger(0, spotify.length - 1);

    while (
      usedSongs.length < 60 &&
      usedSongs.find(
        (s) => s.name.toLowerCase() === spotify[songIndex].name.toLowerCase()
      )
    ) {
      songIndex = randomInteger(0, spotify.length - 1);
    }
    const spotifySong = spotify[songIndex];
    const au = new Audio(spotifySong.preview);
    const s = songs.find(
      (s) => s.name.toLowerCase() === spotifySong.name.toLowerCase()
    );
    setSong({ ...s });
    setUsedSongs((usedSongs) => [...usedSongs, s]);
    setAudio(au);
  };

  useEffect(() => {
    if (!gameStarted) return;
    setAudioLoaded(false);
    setSearch("");
    setMatchedSongs([]);
    setGameCounter(MAX_TIME);
    setLost(false);
    setInitializing(false);
    setInitCounter(3);
    setTimes([]);
    setScore(0);
    setUsedSongs([]);
    getNextSong();
  }, [gameStarted]);

  useEffect(() => {
    if (!audio) return;
    audio.addEventListener("loadedmetadata", handleAudioMetadata);
    audio.addEventListener("canplaythrough", handleAudioLoaded, false);
    setAnswer("");
    setCorrectGuess(-1);
  }, [audio]);

  useEffect(() => {
    if (search.trim() === "" || searchSelected) {
      setMatchedSongs([]);
      return;
    }

    let normalizedSearch = normalizeText(search);

    let matched = songs.filter((s) =>
      normalizeText(s.name).startsWith(normalizedSearch)
    );
    matched = matched.concat(
      ...songs.filter(
        (s) =>
          !matched.find((m) => m.name === s.name) &&
          normalizeText(s.name).includes(normalizedSearch)
      )
    );

    setMatchedSongs(matched);
  }, [search, searchSelected]);

  const handleKey = (e) => {
    if (e.key === " " || e.key === "Enter") {
      if (!gameStarted) {
        setGameStarted(true);
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKey);

    return () => window.removeEventListener("keydown", handleKey);
  }, [gameStarted]);

  useEffect(() => {
    if (times.length > 0) forceUpdate();
  }, [times]);

  const handleGuess = (guess) => {
    if (guessedCorrectly) return;
    setGuessed(guess);
    if (song.name === guess.name) {
      setScore((score) => score + 1);
      setTimes((times) => [...times, MAX_TIME - gameCounter + 1]);
      setGuessedCorrectly(true);
      setAudioLoaded(false);
      setTimeout(() => {
        setSearch("");
        setGuessed(null);
        getNextSong();
        setGuessedCorrectly(false);
      }, 300);
    } else {
      setTimeout(() => setGuessed(null), 300);
    }
  };

  useEffect(() => {
    if (!initializing) return;
    initCounterInterval = setInterval(decrementInitCounter, 500);
  }, [initializing]);

  useEffect(() => {
    if (lost) {
      setFocused(false);
      setSearch("");
      setAudioLoaded(false);
      setGameStarted(false);
      if (score > state.best) {
        let newState = {
          lastPlayed: moment(),
          best: score,
        };
        setState(newState);
        localStorage.setItem("swiftle-rapid", JSON.stringify(newState));
      }
    }
  }, [lost, score, state]);

  const decrementInitCounter = () => {
    setInitCounter((initCounter) => {
      if (initCounter - 1 === 0) {
        clearInterval(initCounterInterval);
        setGameStarted(true);
      }
      return initCounter - 1;
    });
  };

  useEffect(() => {
    if (!audioLoaded) {
      setGameCounter(MAX_TIME);
      clearInterval(gameCounterInterval);
      return;
    }
    gameCounterInterval = setInterval(decrementGameCounter, 1000);
  }, [audioLoaded]);

  useEffect(() => {
    if (gameCounter === 0) setLost(true);
  }, [gameCounter]);

  const decrementGameCounter = () => {
    setGameCounter((gameCounter) => {
      if (gameCounter - 1 === 0) {
        clearInterval(gameCounterInterval);
      }
      return gameCounter - 1;
    });
  };

  return (
    <>
      <Header
        subtitle="Rapid"
        hideStats
        setIsHowToPlayOpen={setIsHowToPlayOpen}
        setIsSettingsOpen={setIsSettingsOpen}
        setIsModeSelectorOpen={setIsModeSelectorOpen}
      />
      <div className="swiftle-rapid-playfield">
        <Player
          hide={!gameStarted}
          theme={theme}
          autoplay
          audio={audio}
          audioStart={audioStart}
          clipDuration={TOTAL_LENGTH}
          answer={answer}
          setClipPlayed={() => {}}
          userInteracted={true}
          correctGuess={correctGuess}
          maxLength={TOTAL_LENGTH}
          actualLength={TOTAL_LENGTH}
          audioLoaded={audioLoaded}
        />
        <Score
          score={score}
          best={state?.best}
          times={times}
          lost={lost}
          showToast={showToast}
        />
        {lost && (
          <div id="missed-answer">
            Answer: <span>{song?.name}</span>
          </div>
        )}
        {!gameStarted ? (
          <button
            className={!initializing ? "playbutton" : "playbutton transparent"}
            onClick={() => {
              setGameStarted(true);
            }}>
            <p>{!initializing ? "PLAY" : initTexts[initCounter - 1]}</p>
          </button>
        ) : (
          <Search
            MAX_TIME={MAX_TIME}
            hide={!gameStarted}
            theme={theme}
            setReferenceElement={setReferenceElement}
            search={search}
            setSearch={setSearch}
            searchSelected={searchSelected}
            setSearchSelected={setSearchSelected}
            handleGuess={handleGuess}
            setFocused={setFocused}
            matchedSongs={matchedSongs}
            audioLoaded={audioLoaded}
            gameCounter={gameCounter}
          />
        )}

        <div
          className={`songs ${
            matchedSongs.length > 0 ? "songs-has-songs" : ""
          }`}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}>
          {matchedSongs.slice(0, 3).map((item) => (
            <div
              key={item.name}
              className={
                guessed?.name === item.name
                  ? guessed.name === song.name
                    ? "song correct"
                    : "song wrong"
                  : "song"
              }
              onPointerDown={(e) => e.preventDefault()}
              onClick={() => {
                handleGuess(item);
              }}>
              <p>{item.name}</p>
            </div>
          ))}
        </div>
        <HowToPlay
          theme={theme}
          isOpen={isHowToPlayOpen}
          setIsOpen={setIsHowToPlayOpen}
        />
        <ModeSelector
          theme={theme}
          isOpen={isModeSelectorOpen}
          setIsOpen={setIsModeSelectorOpen}
          changeMode={(mode, data) => {
            setIsModeSelectorOpen(false);
            changeMode(mode, data);
          }}
        />
        <Settings
          mode="rapid"
          theme={theme}
          setTheme={setTheme}
          isOpen={isSettingsOpen}
          setIsOpen={setIsSettingsOpen}
        />
      </div>
    </>
  );
}
