import React from "react";
import CustomModal from "./CustomModal.js";

export default function Update(props) {
  return (
    <CustomModal
      {...props}
      heading={"Reach out"}
      contentLabel="What's New"
      ariaHideApp={true}
    >
      <div
        style={{
          width: "90%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p style={{ fontSize: 16 }}>
          Has it been too easy? Has it been too hard? <br />
          Help me grow Gomezle into a better game by sharing your thoughts.{" "}
          <br />
          <br /> Follow{" "}
          <a
            style={{ textDecoration: "underline" }}
            href="https://bit.ly/swiftle-twitter"
          >
            <strong>Swiftle (@techyonic)</strong>
          </a>{" "}
          on Twitter to provide feedback and get gameplay updates. I would also
          appreciate if you spread the word around for people looking for a
          heardle for Selena Gomez!
        </p>
      </div>
    </CustomModal>
  );
}
