import React, { useState, useEffect } from "react";
import CustomModal from "./CustomModal.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import momentTimezone from "moment-timezone";
import answers from "../../../config/swiftle/answers.json";
import KofiButton from "kofi-button";
import songs from "../../../config/swiftle/songs.json";

export default function Stats(props) {
  const [stats, setStats] = useState({
    lastPlayed: "",
    totals: {
      played: 0,
      won: 0,
      winPercent: 0,
      currentStreak: 0,
      maxStreak: 0,
    },
    guessDistribution: [0, 0, 0, 0, 0, 0],
  });
  const [countdown, setCountdown] = useState("00:00:00");

  useEffect(() => {
    let s = JSON.parse(localStorage.getItem("swiftle-stats"));
    if (s) setStats(s);
  }, [props.isOpen]);

  useEffect(() => {
    let interval = setInterval(getCountdown, 1000);

    return () => clearInterval(interval);
  }, []);

  const getCountdown = () => {
    const currentDate = moment();
    const nextDate = moment().add(1, "days").startOf("day");
    const diff = nextDate.diff(currentDate);
    setCountdown(moment.utc(diff).format("HH:mm:ss"));
  };

  const handleShare = () => {
    const state = JSON.parse(localStorage.getItem("swiftle-state"));
    if (!state) return;
    const el = document.createElement("textarea");
    let date = moment().add(0, "days").tz(moment.tz.guess());
    let number =
      Object.keys(answers).findIndex((a) => a === date.format("DD-MM-YYYY")) +
      1;
    let text = `Swiftle #${number} ${
      date.format("DD-MM-YYYY") === "13-12-2022" ? "🎉" : ""
    }\n\n`;

    if (props.hardMode.enabled) text += "🎯 Hard Mode\n";

    let wrongGuesses =
      state.correctGuess === -1
        ? state.guesses.length
        : state.guesses.length - 1;

    text += state.correctGuess !== -1 ? "🔊" : "🔇";

    for (let i = 0; i < wrongGuesses; i++) {
      if (state.guesses[i].name === "Skipped") {
        text += "⬛";
        continue;
      }
      let guessedSong = songs.find((s) => s.name === state.guesses[i].name);
      let answerSong = songs.find((s) => s.name === props.answer.name);

      if (guessedSong.album === answerSong.album) text += "🟨";
      else text += "🟥";
    }
    if (state.correctGuess !== -1) text += "🟩";
    for (let i = state.guesses.length; i < 6; i++) text += "⬜";

    if (date.format("DD-MM-YYYY") === "07-05-2022")
      text += "\n\n#ThisLoveTaylorsVersion\nhttps://techyonic.co/swiftle";
    else if (date.format("DD-MM-YYYY") === "26-06-2022")
      text += "\n\n#Carolina\nhttps://techyonic.co/swiftle";
    else if (date.format("DD-MM-YYYY") === "13-12-2022")
      text += "\n\n#HappyBirthdayTS\nhttps://techyonic.co/swiftle";
    else if (
      ["04-07-2023", "05-07-2023", "06-07-2023", "07-07-2023"].includes(
        date.format("DD-MM-YYYY")
      )
    )
      text += "\n\n#SpeakNowTaylorsVersion\nhttps://techyonic.co/swiftle";
    else text += "\n\nhttps://techyonic.co/swiftle";
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    props.showToast("Copied to clipboard");
  };

  return (
    <CustomModal
      {...props}
      heading={"Statistics"}
      contentLabel="Statistics"
      ariaHideApp={true}>
      <div className="totals">
        <div className="number">
          <p className="value">{stats.totals.played}</p>
          <p className="name">Played</p>
        </div>
        <div className="number">
          <p className="value">{Math.ceil(stats.totals.winPercent)}</p>
          <p className="name">Win %</p>
        </div>
        <div className="number">
          <p className="value">{stats.totals.currentStreak}</p>
          <p className="name">Current Streak</p>
        </div>
        <div className="number">
          <p className="value">{stats.totals.maxStreak}</p>
          <p className="name">Max Streak</p>
        </div>
      </div>
      <div className="guess-distribution">
        <p>Guess Distribution</p>
        {stats.guessDistribution.map((item, index) => (
          <div className="row" key={index}>
            <div className="key">{index + 1}</div>
            <div
              className="bar"
              style={{
                width:
                  (200 *
                    ((item * 100) / Math.max(...stats.guessDistribution))) /
                    100 +
                  10,
              }}>
              {item}
            </div>
          </div>
        ))}
      </div>
      <div className="divider" />
      <KofiButton
        color="#29abe0"
        title="Support the developer and servers on Ko-Fi!"
        kofiID="V7V4BN84C"
      />
      <p style={{ fontSize: 16 }}>
        {" "}
        Follow{" "}
        <a
          style={{ textDecoration: "underline" }}
          href="https://bit.ly/swiftle-twitter">
          <strong>Swiftle</strong>
        </a>{" "}
        on Twitter for more clowning.
      </p>
      <div
        className="next-share"
        style={{
          justifyContent: props.gameEnded ? "space-between" : "center",
          marginTop: 14,
        }}>
        <div className="countdown">
          <p className="name">Meet Me At Midnight</p>
          <p className="value">{countdown}</p>
        </div>
        {props.gameEnded && (
          <div className="share">
            <button onClick={handleShare}>
              <FontAwesomeIcon icon={faShareNodes} className="icon" />
              <p>Share</p>
            </button>
          </div>
        )}
      </div>
    </CustomModal>
  );
}
