import React from "react";
import { Helmet } from "react-helmet-async";

export default function CustomHelmet() {
  return (
    <Helmet>
      <title>Gomezle - Selena Gomez Heardle</title>
      <meta
        name="description"
        content="Selena Gomez Heardle - Gomezle, guess the Selena Gomez song from a clip in 6 tries"
        data-react-helmet="true"
      />
      <link rel="canonical" href="https://techyonic.co/gomezle" />
    </Helmet>
  );
}
