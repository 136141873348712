import React from "react";
import CustomModal from "./CustomModal.js";

export default function AnswerVideo(props) {
  return (
    <CustomModal
      {...props}
      heading={"Answer"}
      contentLabel="Answer"
      ariaHideApp={true}
    >
      <div className="modal-answer">
        <iframe
          width="260"
          height="146"
          src={props.answer.youtube}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          style={{ borderRadius: 10 }}
        ></iframe>
        <p className="name">{props.answer.name}</p>
        <p className="album-year">
          {props.answer.album}, {props.answer.year}
        </p>
      </div>
    </CustomModal>
  );
}
