import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Layout from "./containers/Layout";
import Landing from "./screens/Landing.js";
import Swiftle from "./screens/Swiftle.js";
import Gomezle from "./screens/Gomezle.js";
import Merch from "./screens/Merch.js";
import HerePrivacyPolicy from "./screens/HerePrivacyPolicy.js";
import AnElmwoodTrailPrivacyPolicy from "./screens/AnElmwoodTrailPrivacyPolicy.js";

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter basename="/">
        <Layout>
          <Routes>
            <Route exact path="/" element={<Landing />} />
            <Route exact path="/swiftle" element={<Swiftle />} />
            <Route path="/swiftle/:mode" element={<Swiftle />} />
            <Route path="/gomezle" element={<Gomezle />} />
            <Route path="/merch" element={<Merch />} />
            <Route path="here-privacy-policy" element={<HerePrivacyPolicy />} />
            <Route
              path="an-elmwood-trail-privacy-policy"
              element={<AnElmwoodTrailPrivacyPolicy />}
            />
          </Routes>
        </Layout>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
