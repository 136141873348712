import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCubes,
  faCircleQuestion,
  faChartSimple,
  faGear,
  faCakeCandles,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const dateFormat = "DD-MM-YYYY";
const today = moment().add(0, "days").format(dateFormat);

export default function Header({
  subtitle,
  setIsHowToPlayOpen,
  setIsModeSelectorOpen,
  setIsStatsOpen,
  setIsSettingsOpen,
  hideStats,
}) {
  return (
    <div className="swiftle-header">
      <div className="header-content">
        <div className="header-left">
          <button onClick={() => setIsHowToPlayOpen(true)}>
            <FontAwesomeIcon icon={faCircleQuestion} className="icon" />
          </button>
          <button
            style={{ position: "relative" }}
            onClick={() => {
              console.log("set");
              setIsModeSelectorOpen(true);
            }}>
            <FontAwesomeIcon
              style={{ fontSize: 22 }}
              icon={faCubes}
              className="icon"
            />
            <div
              style={{
                position: "absolute",
                top: 4,
                right: 4,
                width: 8,
                height: 8,
                borderRadius: 100,
                backgroundColor: "yellow",
              }}
            />
          </button>
        </div>
        <div className="header-title">
          <a href="/swiftle">
            <h1>Swiftle</h1>
            {today === "16-03-2023" && (
              <FontAwesomeIcon icon={faCakeCandles} className="icon" />
            )}
          </a>
          <h2>{subtitle || "Taylor Swift Heardle"}</h2>
        </div>
        <div className="header-right">
          {!hideStats && (
            <button onClick={() => setIsStatsOpen(true)}>
              <FontAwesomeIcon icon={faChartSimple} className="icon" />
            </button>
          )}
          <button
            style={{ marginLeft: 6 }}
            onClick={() => setIsSettingsOpen(true)}>
            <FontAwesomeIcon icon={faGear} className="icon" />
          </button>
        </div>
      </div>
    </div>
  );
}
