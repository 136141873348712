import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { faListMusic } from "@fortawesome/pro-solid-svg-icons";

const numbers = ["1️⃣", "2️⃣", "3️⃣", "4️⃣", "5️⃣", "6️⃣", "7️⃣", "8️⃣"];

export default function Score({ score, best, times, lost, showToast }) {
  const handleShare = () => {
    let text = `Swiftle Lyrics\n\n🔥 x${score}`;
    //text += times.map((t) => numbers[t - 1]).join("");
    text += "\n\nhttps://techyonic.co/swiftle/lyrics";
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    showToast("Copied to clipboard");
  };

  return (
    <div id="score" style={{ marginTop: "4%" }}>
      <div id="current">
        <FontAwesomeIcon id="icon" icon={faListMusic} />
        <p>{score}</p>
      </div>
      <p id="best">Best: {best}</p>
      {/* <div id="times">
        {times.map((t, index) => (
          <div key={index} id="time">
            <p>{t}</p>
          </div>
        ))}
      </div> */}
      {lost && (
        <button id="share" onClick={handleShare}>
          <FontAwesomeIcon icon={faShareNodes} className="icon" />
          <p>Share</p>
        </button>
      )}
    </div>
  );
}
