import React, { useState, useEffect } from "react";
import { usePopper } from "react-popper";
import moment from "moment";
import Header from "../Header.js";
import HowToPlay from "../modals/HowToPlay.js";
import TimelessSelector from "../modals/TimelessSelector.js";
import Stats from "../modals/Stats.js";
import Settings from "../modals/Settings.js";
import AnswerVideo from "../modals/AnswerVideo.js";
import Player from "../Player.js";
import Search from "../Search.js";
import Answer from "../Answer.js";
import songs from "../../../config/gomezle/songs.json";
import answers from "../../../config/gomezle/answers.json";
import toasts from "../../../config/gomezle/toasts.json";
import { normalizeText } from "../../../utils/swiftle/normalizeText.js";
import swiftleBG from "../../../assets/swiftle-bg.png";
import { useNavigate } from "react-router-dom";

const dateFormat = "DD-MM-YYYY";
const initialDate = moment().add(0, "days").format(dateFormat);
let ans = answers[initialDate];
let audio;
if (ans.includes("-202")) {
  audio = new Audio(`../gomezle/clips/${ans}.mp3`);
} else {
  audio = new Audio(`../gomezle/clips/${initialDate}.mp3`);
}
let CLIP_INITIAL_DURATION = 1;
let CLIP_STEP_DURATION = 1;
const CLIP_ACTUAL_DURATION = 6;

console.log(initialDate);

export default function Classic({
  theme,
  setTheme,
  setFocused,
  showToast,
  gameEnded,
  setGameEnded,
  changeMode,
}) {
  const navigate = useNavigate();
  const [date] = useState(initialDate);
  const [isHowToPlayOpen, setIsHowToPlayOpen] = useState(false);
  const [isTimelessSelectorOpen, setIsTimelessSelectorOpen] = useState(false);
  const [isStatsOpen, setIsStatsOpen] = useState(false);
  const [isAnswerOpen, setIsAnswerOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [hardMode, setHardMode] = useState({ enabled: false, nextChange: "" });
  const [guesses, setGuesses] = useState([]);
  const [search, setSearch] = useState("");
  const [searchSelected, setSearchSelected] = useState(null);
  const [matchedSongs, setMatchedSongs] = useState([]);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes, forceUpdate } = usePopper(
    referenceElement,
    popperElement,
    { placement: guesses.length < 3 ? "bottom" : "top" }
  );
  const [clipDuration, setClipDuration] = useState(CLIP_INITIAL_DURATION);
  const [clipPlayed, setClipPlayed] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);
  const [correctGuess, setCorrectGuess] = useState(-1);
  const [answer, setAnswer] = useState(false);

  useEffect(() => {
    setAnswer(
      songs.find(
        (s) =>
          s.name ===
          (answers[date].includes("-202")
            ? answers[answers[date]]
            : answers[date])
      )
    );
    setLocalStorage();
  }, []);

  useEffect(() => {
    if (hardMode.enabled) {
      CLIP_INITIAL_DURATION = 0.5;
      CLIP_STEP_DURATION = 0.5;
      setClipDuration(CLIP_INITIAL_DURATION);
    }
  }, [hardMode]);

  useEffect(() => {
    if (search.trim() === "" || searchSelected) {
      setMatchedSongs([]);
      return;
    }

    let normalizedSearch = normalizeText(search);

    let matched = songs.filter((s) =>
      normalizeText(s.name).startsWith(normalizedSearch)
    );
    matched = matched.concat(
      ...songs.filter(
        (s) =>
          !matched.find((m) => m.name === s.name) &&
          normalizeText(s.name).includes(normalizedSearch)
      )
    );

    setMatchedSongs(matched);
  }, [search, searchSelected]);

  useEffect(() => {
    let state = JSON.parse(localStorage.getItem("gomezle-state"));
    if (!state) return;
    if (state.date !== date) return;

    state.guesses = guesses;
    localStorage.setItem("gomezle-state", JSON.stringify(state));

    if (forceUpdate) forceUpdate();
    setSearch("");
    setSearchSelected(null);
    if (guesses.length > 0) {
      let guessedCorrectly = guesses[guesses.length - 1].name === answer.name;

      if (guessedCorrectly) {
        setClipDuration(CLIP_ACTUAL_DURATION);
        setCorrectGuess(guesses.length - 1);
        setGameEnded(true);
      } else if (guesses.length >= 6) {
        setClipDuration(CLIP_ACTUAL_DURATION);
        if (date === "01-04-2022") showToast("Happy April Fool's Day");
        else showToast(toasts["-"]);
        setGameEnded(true);
      } else {
        setClipDuration(
          CLIP_INITIAL_DURATION + guesses.length * CLIP_STEP_DURATION
        );
      }
    }
  }, [guesses, forceUpdate, answer, hardMode]);

  useEffect(() => {
    if (correctGuess === -1) return;
    let state = JSON.parse(localStorage.getItem("gomezle-state"));
    let stats = JSON.parse(localStorage.getItem("gomezle-stats"));

    if (stats.lastWon === date) return;
    if (stats.lastPlayed !== date) return;

    if (date === "01-04-2022") showToast("Happy April Fool's Day");
    else showToast(toasts[`${correctGuess + 1}`]);

    state.correctGuess = correctGuess;
    localStorage.setItem("gomezle-state", JSON.stringify(state));

    stats.totals.won += 1;
    stats.totals.winPercent = (stats.totals.won * 100) / stats.totals.played;
    if (stats.lastWon === "") stats.totals.currentStreak = 1;
    else if (
      moment(date, dateFormat).subtract(1, "days").format(dateFormat) ===
      stats.lastWon
    )
      stats.totals.currentStreak += 1;
    else stats.totals.currentStreak = 1;
    stats.lastWon = date;
    if (stats.totals.currentStreak > stats.totals.maxStreak)
      stats.totals.maxStreak = stats.totals.currentStreak;
    stats.guessDistribution[correctGuess] += 1;
    localStorage.setItem("gomezle-stats", JSON.stringify(stats));
    setTimeout(() => setIsStatsOpen(true), 1200);
  }, [correctGuess]);

  const handleGuess = () => {
    if (search.trim() === "" || !searchSelected) return;

    if (!clipPlayed && guesses.length === 0) {
      showToast("You haven't listened to the clip yet");
      return;
    }

    setUserInteracted(true);

    setGuesses((guesses) => {
      return [...guesses, searchSelected];
    });

    let stats = JSON.parse(localStorage.getItem("gomezle-stats"));
    if (stats.lastPlayed !== date) {
      stats.lastPlayed = date;
      stats.totals.played += 1;
      localStorage.setItem("gomezle-stats", JSON.stringify(stats));
    }
  };

  const setLocalStorage = () => {
    let theme = JSON.parse(localStorage.getItem("gomezle-theme"));
    if (!theme) {
      let newTheme = {
        cbMode: false,
      };
      localStorage.setItem("gomezle-theme", JSON.stringify(newTheme));
    } else {
      setTheme(theme);
    }

    let state = JSON.parse(localStorage.getItem("gomezle-state"));

    if (!state) {
      setGuesses([]);
      setCorrectGuess(-1);
      setGameEnded(false);
      let newState = {
        date: date,
        guesses: [],
        correctGuess: -1,
        hardmode: {
          enabled: false,
          nextChange: "",
        },
      };
      localStorage.setItem("gomezle-state", JSON.stringify(newState));
    } else if (state.date === date) {
      setGuesses(state.guesses);
      setCorrectGuess(state.correctGuess);
      setGameEnded(false);
      if (!state.hardmode) {
        state.hardmode = { enabled: false, nextChange: "" };
        setHardMode(state.hardmode);
        localStorage.setItem("gomezle-state", JSON.stringify(state));
      } else {
        setHardMode(state.hardmode);
      }
    } else {
      setGuesses([]);
      setCorrectGuess(-1);
      setGameEnded(false);
      let newState = {
        date: date,
        guesses: [],
        correctGuess: -1,
      };
      if (!state.hardmode) {
        newState.hardmode = { enabled: false, nextChange: "" };
        setHardMode(newState.hardmode);
      } else {
        if (state.hardmode.nextChange !== "") {
          const today = moment(date, dateFormat);
          const changingDate = moment(state.hardmode.nextChange, dateFormat);
          if (today.isSameOrAfter(changingDate)) {
            const toggled = !state.hardmode.enabled;
            newState.hardmode = { enabled: toggled, nextChange: "" };
            setHardMode(newState.hardmode);
          } else {
            newState.hardmode = state.hardmode;
            setHardMode(newState.hardmode);
          }
        } else {
          newState.hardmode = state.hardmode;
          setHardMode(newState.hardmode);
        }
      }
      localStorage.setItem("gomezle-state", JSON.stringify(newState));
    }

    let stats = JSON.parse(localStorage.getItem("gomezle-stats"));
    if (!stats) {
      let newStats = {
        lastPlayed: "",
        lastWon: "",
        totals: {
          played: 0,
          won: 0,
          winPercent: 0,
          currentStreak: 0,
          maxStreak: 0,
        },
        guessDistribution: [0, 0, 0, 0, 0, 0],
      };
      localStorage.setItem("gomezle-stats", JSON.stringify(newStats));
    } else {
      const yesterday = moment(date, dateFormat)
        .subtract(1, "days")
        .format(dateFormat);
      if (stats.lastWon !== yesterday && stats.lastWon !== date) {
        stats.totals.currentStreak = 0;
        localStorage.setItem("gomezle-stats", JSON.stringify(stats));
      }
    }
  };

  const toggleHardMode = () => {
    setHardMode((hardMode) => {
      if (hardMode.nextChange !== "") {
        hardMode.nextChange = "";
        let state = JSON.parse(localStorage.getItem("gomezle-state"));
        state.hardmode = hardMode;
        localStorage.setItem("gomezle-state", JSON.stringify(state));
      } else {
        const tomorrow = moment(date, dateFormat)
          .add(1, "days")
          .format(dateFormat);
        hardMode.nextChange = tomorrow;
        let state = JSON.parse(localStorage.getItem("gomezle-state"));
        state.hardmode = hardMode;
        localStorage.setItem("gomezle-state", JSON.stringify(state));
      }

      return { ...hardMode };
    });
  };

  const handleSkip = () => {
    if (!clipPlayed && guesses.length === 0) {
      showToast("You haven't listened to the clip yet");
      return;
    }

    setUserInteracted(true);

    setGuesses((guesses) => {
      return [...guesses, { name: "Skipped", album: "", year: "" }];
    });
  };

  return (
    <>
      <Header
        setIsHowToPlayOpen={setIsHowToPlayOpen}
        setIsTimelessSelectorOpen={setIsTimelessSelectorOpen}
        setIsStatsOpen={setIsStatsOpen}
        setIsSettingsOpen={setIsSettingsOpen}
      />
      <div className="gomezle-playfield">
        <Player
          theme={theme}
          date={date}
          audio={audio}
          clipDuration={clipDuration}
          guesses={guesses}
          answer={answer}
          setClipPlayed={setClipPlayed}
          userInteracted={userInteracted}
          correctGuess={correctGuess}
          maxLength={gameEnded ? 6 : hardMode.enabled ? 3 : 6}
        />
        {!gameEnded && (
          <button className="skip">
            <p onClick={handleSkip}>Skip</p>
          </button>
        )}
        <div className="guesses">
          {guesses.map((item, index) => {
            let styling = "";
            if (item.name === "Skipped") {
              styling = "skipped";
            } else {
              let answerSong = songs.find((s) => s.name === answer.name);
              let guessedSong = songs.find((s) => s.name === item.name);
              if (item.name === answer.name) styling = "correct";
              else if (guessedSong.album === answerSong.album)
                styling = "album";
            }

            return (
              <div
                key={index}
                className={`guess ${styling} ${theme.cbMode ? "cbmode" : ""}`}>
                <p className={`name`}>{item.name}</p>
              </div>
            );
          })}
        </div>
        {!gameEnded && (
          <Search
            theme={theme}
            setReferenceElement={setReferenceElement}
            search={search}
            setSearch={setSearch}
            searchSelected={searchSelected}
            setSearchSelected={setSearchSelected}
            handleGuess={handleGuess}
            setFocused={setFocused}
            matchedSongs={matchedSongs}
            guesses={guesses}
          />
        )}
        <div
          className={`songs ${
            matchedSongs.length > 0
              ? guesses.length < 3
                ? "songs-has-songs"
                : "songs-has-songs-alt"
              : ""
          }`}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}>
          {matchedSongs.slice(0, 3).map((item) => (
            <div
              key={item.name}
              className="song"
              onClick={() => {
                setSearch(item.name);
                setSearchSelected(item);
                setMatchedSongs([]);
              }}>
              <p>{item.name}</p>
            </div>
          ))}
        </div>

        {guesses.length < 6 && (
          <div className="guesses">
            {[
              ...Array(
                5 - guesses.length + (correctGuess !== -1 ? 1 : 0)
              ).keys(),
            ].map((item, index) => (
              <div key={index} className="guess empty">
                <p></p>
              </div>
            ))}
          </div>
        )}
        {gameEnded && (
          <Answer
            theme={theme}
            answer={answer}
            setIsAnswerOpen={setIsAnswerOpen}
          />
        )}
        <button
          className="other-game"
          style={{ backgroundImage: `url(${swiftleBG})` }}
          onClick={() => navigate("/swiftle")}>
          <p>Taylor Swift Heardle</p>
        </button>
        <TimelessSelector
          theme={theme}
          isOpen={isTimelessSelectorOpen}
          setIsOpen={setIsTimelessSelectorOpen}
          changeMode={(mode, data) => {
            setIsTimelessSelectorOpen(false);
            changeMode(mode, data);
          }}
        />
        <HowToPlay
          theme={theme}
          isOpen={isHowToPlayOpen}
          setIsOpen={setIsHowToPlayOpen}
        />
        <Settings
          mode="classic"
          theme={theme}
          setTheme={setTheme}
          isOpen={isSettingsOpen}
          setIsOpen={setIsSettingsOpen}
          hardMode={hardMode}
          toggleHardMode={toggleHardMode}
        />

        <Stats
          theme={theme}
          isOpen={isStatsOpen}
          setIsOpen={setIsStatsOpen}
          gameEnded={gameEnded}
          answer={answer}
          showToast={showToast}
          hardMode={hardMode}
        />
        <AnswerVideo
          theme={theme}
          answer={answer}
          isOpen={isAnswerOpen}
          setIsOpen={setIsAnswerOpen}
        />
      </div>
    </>
  );
}
