import React from "react";
import { Helmet } from "react-helmet-async";

export default function CustomHelmet({ title }) {
  return (
    <Helmet>
      <title>
        Swiftle - {title == "Classic" ? "Taylor Swift Heardle" : title}
      </title>
      <meta
        name="description"
        content="Taylor Swift Heardle - Swiftle, guess the Taylor Swift song from a clip in 6 tries"
        data-react-helmet="true"
      />
      <link rel="canonical" href="https://techyonic.co/swiftle" />
    </Helmet>
  );
}
