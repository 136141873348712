import moment from "moment";
import React from "react";

const date = moment().tz(moment.tz.guess());
export default function Answer({ theme, setIsAnswerOpen, answer }) {
  return (
    <div
      className={`answer ${theme.cbMode ? "cbmode" : ""}`}
      onClick={() => setIsAnswerOpen(true)}>
      <h5>Answer</h5>
      <p className="name">{answer.name}</p>
      <p className="album-year">
        {answer.album}, {answer.year}
      </p>
      {(answer.name === "Monologue Song" || answer.name === "Thug Story") && (
        <p style={{ marginTop: 10 }}>Happy April Fool's Day!</p>
      )}{" "}
      {answer.name === "This Love (Taylor's Version)" && (
        <p style={{ marginTop: 10, paddingHorizontal: 10, fontSize: 16 }}>
          <strong>
            💙 Out now! 💙 <br /> Stream it today!
          </strong>
        </p>
      )}
      {answer.name === "Carolina" && (
        <p style={{ marginTop: 10, paddingHorizontal: 10, fontSize: 16 }}>
          <strong>
            🍂 Out now! 🍂 <br /> Stream it today!
          </strong>
        </p>
      )}
      {date.format("DD-MM-YYYY") === "27-10-2023" && (
        <p style={{ marginTop: 10, paddingHorizontal: 10, fontSize: 16 }}>
          <strong>💙 1989 Taylor's Version is out now! 💙</strong>
        </p>
      )}
      {date.format("DD-MM-YYYY") === "19-04-2024" && (
        <p style={{ marginTop: 10, paddingHorizontal: 10, fontSize: 14 }}>
          <strong>✌🏻 The Tortured Poets Department releases today! ✌🏻</strong>
        </p>
      )}
    </div>
  );
}
