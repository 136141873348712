import React, { useEffect, useState } from "react";
import { useSpring, animated } from "@react-spring/web";

export default function Search({
  MAX_TIME,
  hide,
  setReferenceElement,
  search,
  setSearch,
  setSearchSelected,
  setFocused,
  matchedSongs,
  audioLoaded,
  gameCounter,
  handleGuess,
}) {
  function getOS() {
    var userAgent = window.navigator.userAgent,
      platform =
        window.navigator?.userAgentData?.platform || window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (/Linux/.test(platform)) {
      os = "Linux";
    }

    return os;
  }

  const currentOS = getOS();
  const isMobile = currentOS === "iOS" || currentOS === "Android";

  const [timerStyle, timerApi] = useSpring(
    () => ({
      from: { width: "100%" },
    }),
    []
  );

  useEffect(() => {
    if (audioLoaded) {
      timerApi.start({
        from: { width: "100%" },
        to: { width: "0%" },
        config: {
          duration: (MAX_TIME - 0.1) * 1000,
        },
      });
    } else {
      timerApi.stop();
    }
  }, [audioLoaded]);

  return (
    <div
      className={`search ${matchedSongs.length > 0 ? "search-has-songs" : ""}`}
      style={{ opacity: hide ? 0 : 1 }}
      ref={setReferenceElement}>
      <animated.div
        id="timer"
        className={gameCounter <= 3 ? "panic" : ""}
        style={{ ...timerStyle }}></animated.div>
      <input
        type="search"
        autoComplete="none"
        autoFocus
        placeholder={`Type... ${
          !isMobile ? "(Press enter to guess the first option)" : ""
        }`}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
          setSearchSelected(null);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (!isMobile)
              if (matchedSongs.length > 0) handleGuess(matchedSongs[0]);
          }
        }}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setTimeout(() => setFocused(false), 300);
        }}
      />
    </div>
  );
}
