import React, { useState, useEffect } from "react";
import { usePopper } from "react-popper";
import moment from "moment";
import Header from "../Header.js";
import HowToPlay from "../modals/HowToPlay.js";
import ModeSelector from "../modals/ModeSelector.js";
import Settings from "../modals/Settings.js";
import songs from "../../../config/swiftle/songs.json";
import lyricsSongs from "../../../config/swiftle/lyrics.json";
import { normalizeText } from "../../../utils/swiftle/normalizeText.js";
import Score from "../lyrics/Score.js";
import Search from "../lyrics/Search.js";
import { animated, easings, useSprings } from "@react-spring/web";

const MAX_TIME = 9;

function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

let gameCounterInterval;

export default function Lyrics({
  theme,
  setTheme,
  setFocused,
  showToast,
  changeMode,
}) {
  const [lyrics, setLyrics] = useState([[], []]);
  const [lyricsStyles, lyricsApi] = useSprings(
    lyrics[0].length + lyrics[1].length,
    lyrics.flat().map((_, index) => ({
      id: index,
      from: { opacity: 0 },
    })),
    []
  );

  const [song, setSong] = useState(null);
  const [usedSongs, setUsedSongs] = useState([]);
  const [gameStarted, setGameStarted] = useState(false);
  const [score, setScore] = useState(0);
  const [lost, setLost] = useState(false);
  const [times, setTimes] = useState([]);
  const [isHowToPlayOpen, setIsHowToPlayOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isModeSelectorOpen, setIsModeSelectorOpen] = useState(false);
  const [gameCounter, setGameCounter] = useState(MAX_TIME);
  const [guessedCorrectly, setGuessedCorrectly] = useState(false);
  const [guessed, setGuessed] = useState(null);
  const [search, setSearch] = useState("");
  const [searchSelected, setSearchSelected] = useState(null);
  const [matchedSongs, setMatchedSongs] = useState([]);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes, forceUpdate } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: "bottom",
    }
  );

  const [state, setState] = useState(null);

  useEffect(() => {
    let lyricsState = JSON.parse(localStorage.getItem("swiftle-lyrics"));

    if (!lyricsState) {
      let newState = {
        lastPlayed: moment(),
        best: 0,
      };
      setState(newState);
      localStorage.setItem("swiftle-lyrics", JSON.stringify(newState));
    } else {
      setState(lyricsState);
    }
  }, []);

  const getNextSong = () => {
    let songIndex = randomInteger(0, lyricsSongs.length - 1);

    while (
      usedSongs.length < 60 &&
      usedSongs.find((s) => s === lyricsSongs[songIndex].id)
    ) {
      songIndex = randomInteger(0, lyricsSongs.length - 1);
    }
    const lyricsSong = lyricsSongs[songIndex];
    const lyrics = lyricsSong.lyrics;

    let lyricIndex = randomInteger(5, lyrics.length - 5);
    let combinedLength =
      lyrics[lyricIndex].split(" ").length +
      lyrics[lyricIndex + 1].split(" ").length;
    while (
      lyrics[lyricIndex] === lyrics[lyricIndex + 1] ||
      combinedLength < 10 ||
      combinedLength > 20
    ) {
      lyricIndex = randomInteger(5, lyrics.length - 5);
      combinedLength =
        lyrics[lyricIndex].split(" ").length +
        lyrics[lyricIndex + 1].split(" ").length;
    }
    setLyrics([
      ...[lyrics[lyricIndex].split(" "), lyrics[lyricIndex + 1].split(" ")],
    ]);
    setSong({ ...lyricsSong });
    setUsedSongs((usedSongs) => [...usedSongs, lyricsSong.id]);
  };

  useEffect(() => {
    if (!gameStarted) return;
    resetAnimations();
    setSearch("");
    setMatchedSongs([]);
    setGameCounter(MAX_TIME);
    setLost(false);
    setTimes([]);
    setScore(0);
    setUsedSongs([]);
    getNextSong();
  }, [gameStarted]);

  const handleKey = (e) => {
    if (e.key === " " || e.key === "Enter") {
      if (!gameStarted) {
        setGameStarted(true);
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    if (search.trim() === "" || searchSelected) {
      setMatchedSongs([]);
      return;
    }

    let normalizedSearch = normalizeText(search);

    let matched = songs.filter((s) =>
      normalizeText(s.name).startsWith(normalizedSearch)
    );
    matched = matched.concat(
      ...songs.filter(
        (s) =>
          !matched.find((m) => m.name === s.name) &&
          normalizeText(s.name).includes(normalizedSearch)
      )
    );

    setMatchedSongs(matched);
  }, [search, searchSelected]);

  useEffect(() => {
    window.addEventListener("keydown", handleKey);

    return () => window.removeEventListener("keydown", handleKey);
  }, [gameStarted]);

  useEffect(() => {
    if (times.length > 0) if (forceUpdate) forceUpdate();
  }, [times]);

  const handleGuess = (guess) => {
    if (guessedCorrectly) return;
    setGuessed(guess);
    if (normalizeText(song.name) === normalizeText(guess.name)) {
      setScore((score) => score + 1);
      setTimes((times) => [...times, MAX_TIME - gameCounter + 1]);
      setGuessedCorrectly(true);
      resetAnimations();
      setTimeout(() => {
        setLyrics([...[[], []]]);
        setSearch("");
        setGuessed(null);
        getNextSong();
        setGuessedCorrectly(false);
      }, 300);
    } else {
      setTimeout(() => setGuessed(null), 300);
    }
  };

  useEffect(() => {
    if (lost) {
      setLyrics([...[[], []]]);
      setFocused(false);
      setSearch("");
      resetAnimations();
      setGameStarted(false);
      if (score > state.best) {
        let newState = {
          lastPlayed: moment(),
          best: score,
        };
        setState(newState);
        localStorage.setItem("swiftle-lyrics", JSON.stringify(newState));
      }
    }
  }, [lost, score, state]);

  const resetAnimations = () => {
    setGameCounter(MAX_TIME);
    clearInterval(gameCounterInterval);
  };

  useEffect(() => {
    if (lyrics[0].length === 0 || lyrics[1].length === 0) return;
    if (forceUpdate) forceUpdate();
    gameCounterInterval = setInterval(decrementGameCounter, 1000);

    const totalWords = lyrics[0].length + lyrics[1].length;
    lyricsApi.start((index) => ({
      id: index,
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: {
        duration: (MAX_TIME * 1000) / 2 / totalWords,
      },
      delay: ((MAX_TIME * 1000) / 2 / totalWords) * index,
      easing: easings.easeOutSine,
    }));
  }, [lyrics]);

  useEffect(() => {
    if (gameCounter === 0) setLost(true);
  }, [gameCounter]);

  const decrementGameCounter = () => {
    setGameCounter((gameCounter) => {
      if (gameCounter - 1 === 0) {
        clearInterval(gameCounterInterval);
      }
      return gameCounter - 1;
    });
  };

  return (
    <>
      <Header
        subtitle="Lyrics"
        hideStats
        setIsHowToPlayOpen={setIsHowToPlayOpen}
        setIsSettingsOpen={setIsSettingsOpen}
        setIsModeSelectorOpen={setIsModeSelectorOpen}
      />
      <div className="swiftle-lyrics-playfield">
        <Score
          score={score}
          best={state?.best}
          lost={lost}
          showToast={showToast}
        />
        {lost && (
          <div id="missed-answer">
            Answer: <span>{song?.name}</span>
          </div>
        )}
        {!gameStarted ? (
          <button
            className={"playbutton"}
            onClick={() => {
              setGameStarted(true);
            }}>
            <p>PLAY</p>
          </button>
        ) : (
          <>
            <div id="lyrics">
              <p>
                {lyrics[0].map((line, index) => (
                  <animated.span
                    style={{ ...lyricsStyles[index] }}
                    key={`0-${index}`}>{`${line} `}</animated.span>
                ))}
              </p>
              <p>
                {lyrics[1].map((line, index) => (
                  <animated.span
                    style={{ ...lyricsStyles[lyrics[0].length + index] }}
                    key={`1-${index}`}>{`${line} `}</animated.span>
                ))}
              </p>
            </div>
            <Search
              MAX_TIME={MAX_TIME}
              score={score}
              hide={!gameStarted}
              theme={theme}
              setReferenceElement={setReferenceElement}
              search={search}
              setSearch={setSearch}
              searchSelected={searchSelected}
              setSearchSelected={setSearchSelected}
              handleGuess={handleGuess}
              setFocused={setFocused}
              matchedSongs={matchedSongs}
              gameCounter={gameCounter}
            />
          </>
        )}

        <div
          className={`songs ${
            matchedSongs.length > 0 ? "songs-has-songs" : ""
          }`}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}>
          {matchedSongs.slice(0, 3).map((item) => (
            <div
              key={item.name}
              className={
                guessed?.name === item.name
                  ? normalizeText(guessed.name) === normalizeText(song.name)
                    ? "song correct"
                    : "song wrong"
                  : "song"
              }
              onPointerDown={(e) => e.preventDefault()}
              onClick={() => {
                handleGuess(item);
              }}>
              <p>{item.name}</p>
            </div>
          ))}
        </div>
        <HowToPlay
          theme={theme}
          isOpen={isHowToPlayOpen}
          setIsOpen={setIsHowToPlayOpen}
        />
        <ModeSelector
          theme={theme}
          isOpen={isModeSelectorOpen}
          setIsOpen={setIsModeSelectorOpen}
          changeMode={(mode, data) => {
            setIsModeSelectorOpen(false);
            changeMode(mode, data);
          }}
        />
        <Settings
          mode="lyrics"
          theme={theme}
          setTheme={setTheme}
          isOpen={isSettingsOpen}
          setIsOpen={setIsSettingsOpen}
        />
      </div>
    </>
  );
}
