import React from "react";

export default function Search({
  hide,
  setReferenceElement,
  search,
  setSearch,
  setSearchSelected,
  handleGuess,
  setFocused,
  matchedSongs,
  guesses,
}) {
  return (
    <div
      className={`search ${
        matchedSongs.length > 0
          ? guesses.length < 3
            ? "search-has-songs"
            : "search-has-songs-alt"
          : ""
      }`}
      style={{ opacity: hide ? 0 : 1 }}
      ref={setReferenceElement}>
      <input
        placeholder="Type your guess..."
        value={search}
        type="search"
        autoComplete="none"
        onChange={(e) => {
          setSearch(e.target.value);
          setSearchSelected(null);
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleGuess();
          }
        }}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setTimeout(() => setFocused(false), 300);
        }}
      />
      <button
        className={`${
          matchedSongs.length > 0
            ? guesses.length < 3
              ? "has-songs"
              : "has-songs-alt"
            : ""
        }`}
        onClick={() => {
          handleGuess();
        }}>
        <p>Enter</p>
      </button>
    </div>
  );
}
